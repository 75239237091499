$('.batch-delete.confirmation-first').click( function (e) {
    e.preventDefault();

    if( confirm('Be careful. Are you sure to proceed the request!?') ) {
        let form = $(this).attr('data-form');
        $(form).submit();
    }
});

$('.batch-export').click( function (e) {
    e.preventDefault();
    let form = $(this).attr('data-form');
    $(form).submit();
});

$('[data-submit]').click( function (e) {
    e.preventDefault();

    let selector = $(this).attr('data-submit');
    document.querySelector(selector).submit();
} );