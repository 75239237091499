$(document).ready( function () {

	// Humanize upon page load
	$('.decimal-points input').each(function() {
		if(this.value !== '') {
			this.value = this.value.toFaPrice();
		}
	});


	// Bind .decimal-points inputs to faPrice
	$(document).on('keyup', '.decimal-points input', function() {
		if(this.value !== '') {
			this.value = this.value.toFaPrice().replace(/(?![\u06F0-\u06F9,0-9])(.*)+/g,'');
		}
	});

	// Clean inputs before submit
	$(document).on('submit', 'form', function() {
		$('.decimal-points input').each(function() {
			if(this.value !== '') {
				this.value = this.value.toCalcNum().toString();
			}
		});
	});

} );