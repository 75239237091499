$('#navbar-search-box').autocomplete({
    lookupLimit: 8,
    minChars: 4,
    serviceUrl: base_url + '/admin/search',
    deferRequestBy: 0,
    onSelect: function (suggestion) {
        $('#navbar-search-url').val( suggestion.url );
        $('#navbar-search-data').val( suggestion.data );
        $('#navbar-search-resource').val( suggestion.resource );

        $('#navbar-search-form').submit();
    }
});