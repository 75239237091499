// String => String (comma separated + persian digits)
String.prototype.toFaPrice = function () {
	//suffix = suffix || 'تومان';
	return	this.toCalcNum()
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
			/*.replace(/\d+/g, function (matched) {
				var result = '';
				for (var i = 0; i < matched['length']; i++) {
					result += String.fromCharCode(matched.charCodeAt(i) + 1728)
				};
				return result
			});
			*/
};

// String => Number
String.prototype.toCalcNum = function () {
    return	parseInt(this
				.replace(/[,،]/g,'')
				.replace(/[\u06F0-\u06F9]+/g, function (matched) {
					var result = '';
					for (var i = 0; i < matched.length; i++) {
						result += String.fromCharCode(matched.charCodeAt(i) - 1728);
					};
					return result
				})
			);
};