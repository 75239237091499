$(document).ready( function () {

    // Make Sortable
    $( ".specification-component > ul" ).sortable();

    // Render Specifications
    renderSpecifications();

    //........................................................................................................

    // Render Specifications Control UI
    function renderSpecifications() {

        // Loop Through All Specifications
        $('.specification-component').each( function () {

            // Get Specification Value
            let specifications = $(this).find('input[type="hidden"]').val();

           // If Not Created Yet, Or Null
            if( specifications == '' ) {
                specifications = "[]";
            }

            // Parse JSON
            specifications = JSON.parse(specifications);

            // If Empty Then Create A Blank Row
            if( specifications.length == 0 ) {

                specifications = [{
                    key: '',
                    value: ''
                }];
            }

            // Create HTML Markup
            let html = '';

            for (let i = 0; i < specifications.length; i++) {

                let key_type = $(this).hasClass('key-color') ? 'color' : 'text';
                let value_type = $(this).hasClass('value-color') ? 'color' : 'text';

                html += '<li class="row specification-row">';
                html += '<div class="col-md-2">';
                html += '<input class="form-control spec-key" placeholder="نام" type="' + key_type + '" value="' + specifications[i].key + '">';
                html += '</div>';
                html += '<div class="col-md-8">';
                html += '<input class="form-control spec-value" placeholder="مقدار" type="' + value_type + '" value="' + specifications[i].value + '">';
                html += '</div>';
                html += '<div class="col-md-2">';
                html += '<a href="#" class="btn link btn-remove">';
                html += '<span class="fa fa-times"> </span>';
                html += '</a>';
                html += '<a href="#" class="btn link btn-add">';
                html += '<span class="fa fa-file-o"></span>';
                html += '</a>';
                html += '<a href="#" class="btn link btn-copy">';
                html += '<span class="fa fa-files-o"> </span>';
                html += '</a>';
                html += '</div>';
                html += '</li>';
                html += '';
            }

            // Inject HTML
            $(this).find('ul').html( html );

            // Make Them Sortable
            $(this).find('ul').sortable({
                stop:  updateValue,
            });

        } );

    }

    //........................................................................................................

    function updateValue() {

        // Loop Through All Specifications
        $('.specification-component').each( function () {

            let newSpecifications = [];

            $(this).find('ul > li').each( function () {

                let key = $(this).find('input.spec-key').val();
                let value = $(this).find('input.spec-value').val();

                if(key != undefined && value != undefined) {

                    newSpecifications.push({
                        'key': key,
                        'value': value
                    })
                }

            } );

            $(this).find('input[type="hidden"]').val( JSON.stringify(newSpecifications) );

            if( $(this).find('input[type="hidden"]').val() == '[]' ) {
                renderSpecifications();
            }

        });

    }

    //........................................................................................................

    $(document).on('keyup', '.specification-component input[type="text"]', function () {
        updateValue();
    } );

    $(document).on('change', '.specification-component input[type="color"]', function () {
        updateValue();
    } );

    //........................................................................................................

    // Add An Item
    $(document).on('click', '.specification-component .btn-add', function (e) {
        e.preventDefault();
        $(this).parent().parent().after($(this).parent().parent().clone());
        $(this).parent().parent().next().find('input').val('');
        updateValue();
    } );

    //........................................................................................................

    // Copy An Item
    $(document).on('click', '.specification-component .btn-copy', function (e) {
        e.preventDefault();
        $(this).parent().parent().after($(this).parent().parent().clone());
        updateValue();
    } );

    // -----------------------------------------------------------------------------------------------------------------


    // Remove An Item
    $(document).on('click','.specification-component .btn-remove', function (e) {
        e.preventDefault();
        $(this).parent().parent().slideUp("normal", function() {
            $(this).remove();
            updateValue();
        } );
    } );

    //........................................................................................................

} );