function ifActiveInitial()
{
    $('input[data-ifActive]').each( function () {

        let elements = $(this).attr('data-ifActive');

        $(elements).attr('readonly', ! $(this).prop('checked'));
        $(elements).attr('disabled', ! $(this).prop('checked'));

        if (! $(this).prop('checked')) {
            $(elements).addClass('disabled');
        } else {
            $(elements).removeClass('disabled');
        }
    } );
}

$('input[data-ifActive]').change( function () {
    ifActiveInitial();
} );

ifActiveInitial();