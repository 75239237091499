document.addEventListener('DOMContentLoaded', function() {
    var form = document.querySelector('[data-ctrl-s=on]');
    if(form) {
        document.addEventListener('keydown', function(e){
            if(e.key == 's' && e.ctrlKey) {
                e.preventDefault();
                if(confirm('آیا از ذخیره شدن این فرم اطمینان دارید؟')) {
                    if (e.altKey) {
                        document.querySelector('[data-ctrl-s=on] [value=save_close]').click();
                    } else {
                        document.querySelector('[data-ctrl-s=on] [value=save]').click();
                    }
                }
                return false;
            }
        });
    }
});
