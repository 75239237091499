$(document).ready( function () {

    // Update on initialize
    if ($('body[class*="page-admin-co_flowers-"] select[name="type"]').length == 1) { updateUI(); }

    // Update on ui change
    $('body[class*="page-admin-co_flowers-"] select[name="type"]').change(updateUI);
    
    // Update UI
    function updateUI() {
        let type = $('body[class*="page-admin-co_flowers-"] select[name="type"]').val();
        console.log(type)
        $('[class*="show-on-"]').hide();
        $('.show-on-' + type).show();
    }
} );