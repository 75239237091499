$(document).ready( function () {

    function onCheckedVisisble (el) {
        if (el.prop('checked')) {
            $( el.attr('data-onCheckedVisisble') ).slideDown();
        } else {
            $( el.attr('data-onCheckedVisisble') ).slideUp();
        }
    }

    $('[data-onCheckedVisisble]').each( function () {
        onCheckedVisisble( $(this) );
    } );
    
    $('[data-onCheckedVisisble]').change( function () {
        onCheckedVisisble( $(this) );
    } );

} );