$(document).ready( function () {

    // Update At Start
    $(".form-control-product").each( function () {
        let checked = JSON.parse( $('input.form-value').val() );
        if (checked != null)  {
            for (let i = 0; i < checked.length; i++) {
                $(this).parent().find("input[value='" + checked[i] + "']").prop('checked', true);
            }
        }
    } );

    checkbox2UpdateValue();

    // Hide Products List Upon Start
    $('.form-control-product .products').hide();

    // Search On KeyUp
    $(".form-control-product > input.form-control").on("keyup", function() {
        let value = $(this).val().toLowerCase();

        $(this).parent().find('div.products .mt-checkbox.option').filter(function() {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });

        checkbox2UpdateValue();
    });

    // Toggle All Item
    $('.toggle-all-filter > input').click( function () {
        let checked = $(this).prop('checked');

        $(this).parent().parent().find('.option > input:visible').prop('checked', checked);

        checkbox2UpdateValue();
    } );

    // Change Any Input
    $('.form-control-product input').change( function () {
        checkbox2UpdateValue();
        $('.form-control-product input[type=text]').focus();
    } );

    // Show-Hide Products List Upon Focus
    $('.form-control-product input[type=text]').focus(function() {
        $(this).parent().find('.products').slideDown();
    });

    $('.form-control-product input[type=text]').blur(function() {
        setTimeout(() => {
            if(!isDescendant(document.querySelector('.form-control-product .products'), document.activeElement) 
                && this !== document.activeElement) {
                $(this).parent().find('.products').slideUp();
            }
        }, 300);
        
    });

    function isDescendant(parent, child) {
        var node = child.parentNode;
        while (node != null) {
            if (node == parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }


    // Update While Working
    function checkbox2UpdateValue() {
        $(".form-control-product").each( function () {

            let values = [];

            $(this).find('.option > input:checked').each( function () {
                values.push( $(this).val() );
            } );


            let json_string = JSON.stringify(values);

            $(this).find('input.form-value').val( json_string );
        } );
    }

} );