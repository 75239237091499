$(document).on('click', '.select-on-click', function(e) {
    e.stopPropagation();
    var elem = this;
    setTimeout(function() {
        if(elem.tagName.toLowerCase() !== 'input' && elem.tagName.toLowerCase() !== 'textarea') {
            elem = $(elem).find('input')[0];
        }
        elem.focus();
        typeof(elem.select) === 'function' ? elem.select() : elem.setSelectionRange(0, elem.value.length);
    }, 0);

});

$(document).on('mouseup', '.select-on-click', function(e) {
    // e.preventDefault();
    var elem = this;
    var _this = this;
    setTimeout(function() {
        if(_this.tagName.toLowerCase() !== 'input' && _this.tagName.toLowerCase() !== 'textarea') {
            elem = $(_this).find('input')[0];
        }
        typeof(elem.select) === 'function' ? elem.select() : elem.setSelectionRange(0, elem.value.length);
    }, 0);
});