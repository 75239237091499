$('input.toggle-all').change( function () {

    var inputs = $(this).closest('table').find('tbody tr td:first-child input[type="checkbox"]');

    inputs.prop('checked', $(this).prop('checked'));

    var selected = '';

    inputs.each( function () {

        if( $(this).prop('checked') ) {
            selected += $(this).val() + ',';
        }

    } );

    $('input[name="_batch"]').val( selected );

} );


$('input.toggle-me').change( function () {

    var inputs = $(this).closest('table').find('tbody tr td:first-child input[type="checkbox"]');

    var selected = '';

    inputs.each( function () {

        if( $(this).prop('checked') ) {
            selected += $(this).val() + ',';
        }

    } );

    $('input[name="_batch"]').val( selected );

} );