$('.datepicker-element select').change( function () {

    // Datepicker Object
    let datepicker = $(this).parent().parent().parent().parent();

    // Get Date Parts
    let year    = datepicker.find('.datepicker-year select').val();
    let month   = datepicker.find('.datepicker-month select').val();
    let day     = datepicker.find('.datepicker-day select').val();
    let hour    = datepicker.find('.datepicker-hour select').val();
    let minute  = datepicker.find('.datepicker-minute select').val();

    // Value
    let val = year + '-' + month + '-' + day + '-' + hour + '-' + minute;

    // Set Value
    datepicker.find('.datepicker-input').val(val);

});