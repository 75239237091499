String.prototype.addCommas = function() {
    return this.replace(/,/g,'').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
Number.prototype.addCommas = function() {
    var result = this.toString();
    return result.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
String.prototype.removeCommas = function() {
    return this.replace(/,/g,'');
}

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};